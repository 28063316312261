/* eslint react/prop-types: 0 */
import React, { Component, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "components/errors/404";
import Palette from "pages/Palettes";
import Gradient from "pages/Gradients";
import Image from "pages/ImageAnalyser";

export default class index extends Component {
  render() {
    return (
      <Switch>
        <Route
          path={["/", "/palettes"]}
          exact
          component={Palette}
        />
        <Route
          path="/gradients"
          exact
          component={Gradient}
        />
        <Route
          path="/image"
          exact
          component={Image}
        />

        <Route path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

import fileDownload from "js-file-download";

// copy color code to file and download it as a text file
export const formatPalette = (palette) => {
  const data = ` primary color \n HEX: ${palette[0]}\n ${hexToRgb(palette[0])}  \n\n secondary color \n HEX: ${palette[1]}\n ${hexToRgb(palette[1])} \n\n tertiary color \n HEX: ${palette[2]}\n ${hexToRgb(palette[2])} \n\n quaternary color \n HEX: ${palette[3]}\n ${hexToRgb(palette[3])}`;

  fileDownload(data, "filename.txt", "text/csv");
};

export const formatPaletteWithRgb = (palette) => {
  const data = ` primary color \n HEX: ${rgbToHex(palette[0])}\n RGB( ${palette[0].toString()} )  \n\n secondary color \n HEX: ${rgbToHex(palette[1])}\n RGB( ${palette[1].toString()} ) \n\n tertiary color \n HEX: ${rgbToHex(palette[2])}\n RGB( ${palette[2].toString()} ) \n\n quaternary color \n HEX: ${rgbToHex(palette[3])}\n RGB(${palette[3].toString()} ) \n\n quinary color \n HEX: ${rgbToHex(palette[3])}\n RGB(${palette[3].toString()} )`;

  fileDownload(data, "filename.txt", "text/csv");
};

export const formatGradient = (palette) => {
  const data = `background: linear-gradient(${palette.toString()})`;

  fileDownload(data, "filename.txt", "text/csv");
};

// convert hex to rgb
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return result ? `RGB(${r}, ${g}, ${b})` : null;
};


// convert rgb to hex
export const rgbToHex = (rgb) => {
  const [r, g, b] = rgb;
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

import React, { useState } from "react";
import {
  ShareAltOutlined,
  ExportOutlined,
  DownloadOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Tooltip, Menu, Dropdown, message, Pagination } from "antd";
import { SketchPicker } from "react-color";
import ClipboardJS from "clipboard";
import MainLayout from "layouts/Main";
import "./style.scss";
import { formatPalette } from "utils/formatter";
import palettes from "db/palettes.json";
import Helmet from "react-helmet";

const Palette = () => {
  const [background, setBackground] = useState("#ffffff");
  const [isColorClicked, setIsColorClicked] = useState(false);

  const sharemenu = (palette) => {
    const tweetText = `Found an awesome Color palette from colors.vickyvictor.com`;

    return (
      <Menu>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?text=${tweetText}`}
          >
            <TwitterOutlined style={{ fontSize: "18px", paddingTop: "-5px" }} />
            <label>Twitter</label>
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  const handleCopy = (mIndex, index) => {
    console.log(`.copy-button-${mIndex}${index}`);
    const clipboard = new ClipboardJS(`.copy-button-${mIndex}${index}`);

    clipboard.on("success", () => {
      message.success("Copied to clipboard");
    });
  };

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div>
      <Helmet title="Palettes" />
      <MainLayout>
        <div className="text-main">
          Curated list of Color Palettes and Gradients
        </div>
        <div className="d-flex flex-row flex-wrap palettes-container">
          {palettes.map((palette, mIndex) => {
            return (
              mIndex >= minIndex &&
              mIndex < maxIndex && (
                <div className="pal-card" key={mIndex}>
                  <div className="d-flex flex-row justify-content-center pt-2">
                    {palette.map((color, index) => {
                      return (
                        <div className="text-center">
                          <Tooltip title="click to copy">
                            <div
                              style={{ background: color }}
                              data-clipboard-target={`.color-code-${mIndex}${index}`}
                              className={`c-box copy-button-${mIndex}${index}`}
                              onClick={() => handleCopy(mIndex, index)}
                            ></div>
                            <label className={`color-code-${mIndex}${index}`}>
                              {color}
                            </label>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex flex-row action-container">
                    <Dropdown overlay={() => sharemenu(palette)}>
                      <ShareAltOutlined />
                    </Dropdown>
                    <DownloadOutlined onClick={() => formatPalette(palette)} />
                  </div>
                </div>
              )
            );
          })}
        </div>
        <div className="text-center pb-5">
          <Pagination
            total={palettes.length}
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </MainLayout>
    </div>
  );
};

export default Palette;

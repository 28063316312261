import React, { useState } from "react";
import {
  ShareAltOutlined,
  ExportOutlined,
  DownloadOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Tooltip, Menu, Dropdown, message, Pagination } from "antd";
import { SketchPicker } from "react-color";
import ClipboardJS from "clipboard";
import MainLayout from "layouts/Main";
import "./style.scss";
import { formatGradient } from "utils/formatter";
import gradients from "db/gradients.json";
import { Helmet } from "react-helmet";

const Gradient = () => {
  const [background, setBackground] = useState("#ffffff");
  const [isColorClicked, setIsColorClicked] = useState(false);

  const sharemenu = (palette) => {
    const tweetText = `Found an awesome Color gradients from colors.vickyvictor.com`;

    return (
      <Menu>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?text=${tweetText}`}
          >
            <TwitterOutlined style={{ fontSize: "18px", paddingTop: "-5px" }} />
            <label>Twitter</label>
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  const handleCopy = (mIndex, index) => {
    console.log(`.copy-button-${mIndex}${index}`);
    const clipboard = new ClipboardJS(`.copy-button-${mIndex}${index}`);

    clipboard.on("success", () => {
      message.success("Copied to clipboard");
    });
  };

  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div>
      <Helmet title="Gradients" />
      <MainLayout>
        <h3 className="text-center pt-5">Color Gradients</h3>
        <p className="sub-text">
          Draw inspiration from curated list of awesome gradients
        </p>
        <div className="d-flex flex-row flex-wrap palettes-container pt-5">
          {gradients.map((gradient, mIndex) => {
            return (
              mIndex >= minIndex &&
              mIndex < maxIndex && (
                <div className="grad-card" key={mIndex}>
                  <div
                    className="bg-container"
                    style={{
                      background: `linear-gradient(${gradient.colors.toString()})`,
                    }}
                  ></div>
                  <div className="d-flex flex-row action-container">
                    <div className="d-flex flex-column text-center">
                      <label>{gradient.name}</label>
                      <div style={{marginTop: "-10px"}}>
                      <Dropdown overlay={() => sharemenu(gradient)}>
                        <ShareAltOutlined />
                      </Dropdown>
                      <DownloadOutlined
                        onClick={() => formatGradient(gradient.colors)}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
        <div className="text-center pb-5">
          <Pagination
            total={gradients.length}
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </MainLayout>
    </div>
  );
};

export default Gradient;

import React, { useState, useEffect, createRef } from "react";
import {
  ShareAltOutlined,
  ExportOutlined,
  DownloadOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, message, Menu, Dropdown } from "antd";
import { SketchPicker } from "react-color";
import ClipboardJS from "clipboard";
import MainLayout from "layouts/Main";
import "./style.scss";
import { formatPalette, rgbToHex, formatPaletteWithRgb } from "utils/formatter";
import palettes from "db/palettes.json";
import Helmet from "react-helmet";
import ColorThief from "colorthief";
import defaultImg from "assets/img/rainbow.png";

const Palette = () => {
  const [imgUrl, setImgUrl] = useState(defaultImg);
  const [imgPalette, setImgPalette] = useState([]);
  useEffect(() => {}, []);

  const imgRef = createRef();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const imageUpload = (e) => {
    const file = e.target.files[0];
    getBase64(file).then((base64) => {
      localStorage["fileBase64"] = base64;
      setImgUrl(base64);
    });
  };

  const handleCopy = (index) => {
    console.log(`.copy-button-${index}`);
    const clipboard = new ClipboardJS(`.copy-button-${index}`);

    clipboard.on("success", () => {
      message.success("Copied to clipboard");
    });
  };
  const sharemenu = (palette) => {
    const tweetText = `I extracted colors from an image using an awesome tool at Colorsheet.com`;

    return (
      <Menu>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?text=${tweetText}`}
          >
            <TwitterOutlined style={{ fontSize: "18px", paddingTop: "-5px" }} />
            <label>Twitter</label>
          </a>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <div>
      <Helmet title="Palettes" />
      <MainLayout>
        <div className="m-container">
          <h3 className="text-center">Image</h3>
          <p className="sub-text">Extraxt color palette from your image</p>
          <div className="analyser-box d-flex flex-column justify-content-center">
            <div>
              <img
                className="img-box"
                crossOrigin={"anonymous"}
                ref={imgRef}
                alt="my cool cat"
                src={imgUrl}
                onLoad={() => {
                  const colorThief = new ColorThief();
                  const img = imgRef.current;
                  const result = colorThief.getPalette(img, 5);
                  setImgPalette(result);
                }}
              />
            </div>
            <div className="mt-3 img-info-box">
              <Input
                type="file"
                id="imageFile"
                name="imageFile"
                onChange={imageUpload}
              />
              <div className="w-100">
                <div className="d-flex flex-row flex-wrap palettes-container">
                  <div
                    style={{ width: "auto", height: "auto" }}
                    className="pal-card pal-card-info"
                  >
                    <div className="d-flex flex-row justify-content-center color-wrapper pt-2">
                      {imgPalette.map((color, index) => {
                        return (
                          <div className="text-center ">
                            <Tooltip title="click to copy">
                              <div
                                style={{
                                  background: `rgb(${color.toString()})`,
                                }}
                                data-clipboard-target={`.color-code-${index}`}
                                className={`c-box c-box-o copy-button-${index}`}
                                onClick={() => handleCopy(index)}
                              ></div>
                              <label className={`color-code-${index}`}>
                                {rgbToHex(color)}
                              </label>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex flex-row action-container">
                      <Dropdown overlay={() => sharemenu()}>
                        <ShareAltOutlined />
                      </Dropdown>
                      <DownloadOutlined
                        onClick={() => formatPaletteWithRgb(imgPalette)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default Palette;

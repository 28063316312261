import React, { useState } from "react";
import {
  BgColorsOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./style.scss";
import { NavLink } from "react-router-dom";

const MainLayout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div>
      <div className="nav-bar">
        <div
          className="logo-container"
          onClick={() => window.location.replace("/")}
        >
          <BgColorsOutlined />
          <label>Color Sheet</label>
        </div>
        <div className="d-flex flex-row menu-links">
          <NavLink className={`nav-link `} to="/palettes">
            Palette
          </NavLink>
          <NavLink to="/gradients" className={`nav-link `}>
            Gradient
          </NavLink>
          <NavLink to="/image" className={`nav-link btn-link`}>
            Upload Image
          </NavLink>
        </div>

        <div className="menu-icon">
          {!isMenuOpen ? (
            <MenuOutlined
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              style={{ fontSize: "25px", color: "#06f" }}
            />
          ) : (
            <CloseOutlined
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              style={{ fontSize: "25px", color: "#06f" }}
            />
          )}
        </div>
      </div>
      {isMenuOpen && (
        <div
          className="d-flex flex-column menu-links-mobile ml-2"
          style={{ width: "150px", marginLeft: "10px" }}
        >
          <NavLink className={`nav-link p-3 `} to="/palettes">
            Palette
          </NavLink>
          <NavLink to="/gradients" className={`nav-link p-3`}>
            Gradient
          </NavLink>
          <span className="pt-3">
            <NavLink to="/image" className={`nav-link btn-link`}>
              Upload Image
            </NavLink>
          </span>
        </div>
      )}
      <div className="m-body">{children}</div>
      <div>
        <p className="text-center p-3">
          Made with 💙 by{" "}
          <a href="https://vickyvictor.com" rel="noreferrer" target="_blank">
            Victor Karangwa
          </a>
        </p>
      </div>
    </div>
  );
};

export default MainLayout;
